import { I18nMessageFunction } from '@/types'

export default {
  params: {
    price: 'Price',
    supply: 'Supply',
    height: 'Height',
    chainData: 'Chain Data',
    totalStake: 'Total Stake',
    blockTime: 'Block time',
    stakingApr: 'Staking APR',
    totalStakers: 'Total Stakers',
    inflationRate: 'Inflation Rate',
    totalBurned: 'Total Burned',
    transactionsCount: 'Transactions Count',
    supplyTotalNote: ({ named }: I18nMessageFunction) =>
      `The estimated total supply of INJ tokens is ${named('supply')} INJ. Injective is a Proof of Stake blockchain with a dynamic token burn schedule.`
  }
}
